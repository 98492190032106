@import url('https://fonts.googleapis.com/css2?family=Fraunces&display=swap');

.App {
  display: flex;
  justify-content: center;
  font-family: 'Fraunces', serif;
  font-size: 1.4vw;
}

.content{
  display: grid;
  grid-template-columns: [left] 1fr [right];
  width: 90%;
}


@media screen and (min-width: 1101px) {
  .App {
    font-size: 17px;
  }
  .profile-image{
    height: 290px;
  }
}

@media (min-width: 701px) and (max-width: 1100px) {
  .App {
    font-size: 1.5vw;
  }
  .profile-image{
    height: 25vw;
  }
}



@media screen and (max-width: 700px) {
  .App {
    font-size: 2.3vw;
  }
  .profile-image{
    height: 25vw;
  }
}

.header {
  display: flex;
  justify-content: center;
  width: auto;
  text-align: center;
  z-index: 1;
  height: 370px;
}

img{
  opacity: 0.4;
  max-width: 100%;
  max-height: 100%;
}

.pancake{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  position: relative;
  height: 390px;
}

.profile-image{
  flex: 0 1 25%;
  background-image: url("profile.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.bio{
  flex: 0 1 40%;
  margin: 40px;
  margin-bottom: 20px;
  font-weight: bold;
}

.header2 {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  filter: contrast(95%);

  background-size: cover;
  text-align: center;
  height: 370px;
  width: auto;
}

.blogs{
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 5%;
}

.header3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  /* overflow-x: auto; */
  filter: contrast(95%);
  font-size: larger;
  padding-left: 7%;
}

.blog-title{
  padding-left: 30px;
  margin: 0px;
  width: 100%;
}

.project-card{
  flex: none;
  width: 300px;
  height: 300px;
  --tw-bg-opacity: 1;
  background-color: rgba(124,58,237,var(--tw-bg-opacity));
  color:white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px 5px 5px 5px;
  margin: 1rem;
  padding: 1rem;
  font-size: medium;
}

.header3 a{
  color: black;
}

.header3 a:hover{
  color: #373F47;
}

/* .project-card:hover{
  display: grid;
  place-items: center;
  width: 50%;
  height: 15rem;
  background-color: #AAABBC;
  color: white;
  border-radius: 20px 20px 0px 0px;
  margin-top: auto;
  padding: 1rem;
} */

button{
  background-color: white;
  border-radius: 10px;
  font-size: larger;
  color: #373F47;
}

button:hover{
  background-color: #AAABBC;
  color: white;
}

a{
  color: #373F47;
  text-decoration: none;
}

a:hover{
  color: white;
  font-style: normal;
}




@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
